import React from 'react'

export const Loading = ({ loading }) => {
    return (
        <>
            {
                loading && (
                    <div className="alert alert-info" role="alert">
                        Cargando...
                    </div>
                )
            }
        </>
    )
}
