import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MainScreen } from '../components/ui/MainScreen';
import { Navbar } from '../components/uiComponents/Navbar';
import { useSelector } from 'react-redux';
import { componentPermissionsMap } from '../permissions/componentPermissionsMap';

export const InternalRoters = () => {

    const { permiso } = useSelector(state => state.auth);

    return (
        <>
            <Navbar />
            <div className="container mt-2">
                <Switch>
                    <Route exact path="/main" component={MainScreen} />
                    {
                        componentPermissionsMap
                            .filter(permisoOnComponet => permisoOnComponet.permiso >= permiso)
                            .map(
                                ({ path, component }) => (
                                    <Route key={path} exact path={path} component={component} />
                                )
                            )
                    }
                    <Redirect to="/main" />
                </Switch>
            </div>
        </>
    )
}
