import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { processError } from '../../helpers/utils';
import { useLocalTaskStatus } from '../../hooks/useLocalTaskStatus';
import { ueDescriptor } from '../../descriptors/ueDescriptor';
import { Input } from '../uiComponents/Input';
import { Table } from '../uiComponents/Table';
import { InfoText } from '../uiComponents/InfoText';
import Swal from 'sweetalert2';
import { Loading } from '../uiComponents/Loading';
import { ErrorAlert } from '../uiComponents/ErrorAlert';
import { altaUeUsr, deleteUeUsr } from '../../helpers/restCalls';
import { useDispatch } from 'react-redux';
import { selectUserData } from '../../actions/um';

export const AltaRelacionUeUser = () => {
    const dispatch = useDispatch();
    const { um, ui, auth } = useSelector(state => state);
    const { selectUser } = um;
    const { ueInputItems } = ui;

    const [error, loading, setError/*, setLoading*/] = useLocalTaskStatus();
    const [ue, setUe] = useState('');

    if (!selectUser) {
        return (
            <Redirect to="userManajament" />
        )
    }

    const addRelUeUsr = async () => {
        if (selectUser && ue) {
            const { id } = selectUser;
            try {
                const ueUsr = await altaUeUsr(id, auth, { ue }, dispatch);
                const user = { ...selectUser, ueUsr };
                dispatch(selectUserData(user));
            } catch (error) {
                console.error(error);
                setError(processError(error));
            }
        }
    }

    const deleteRelUeUsr = ue => {
        Swal.fire({
            title: `¿Borrar la UE "${ue}"?`,
            showCancelButton: true,
            confirmButtonText: `Borrar`,
            cancelButtonText: `Cancelar`,
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    const { id } = selectUser;
                    const ueUsr = await deleteUeUsr(id, ue, auth, dispatch);
                    const user = { ...selectUser, ueUsr };
                    dispatch(selectUserData(user));
                } catch (e) {
                    Swal.fire('Error:', e.message, 'error');
                }
            }
        });
    }


    return (
        <div>
            <h3>{selectUser.nombre}</h3>
            <div className="row">
                <div className="col-auto pt-2">
                    <Input
                        key={ueDescriptor.name}
                        value={ue}
                        onChange={({ target }) => setUe(target.value)}
                        {...ueDescriptor}
                    />
                </div>
                {
                    ue && (
                        <div className="col position-relative">
                            <button
                                className="btn btn-outline-secondary position-absolute top-50 translate-middle-y"
                                onClick={() => addRelUeUsr()}
                            >
                                &#10133; Agregar
                            </button>
                        </div>
                    )
                }

            </div>
            <div>
                <Loading loading={loading} />
                <ErrorAlert error={error} />
                <Table
                    titles={['Controles', 'UE', 'Descripción']}
                    rowsData={selectUser.ueUsr}
                    rowsFn={
                        ue => (
                            <tr
                                key={`ue${ue}`}
                            >
                                <td>
                                    <button
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() => deleteRelUeUsr(ue)}
                                    >
                                        &#10007;
                                    </button>
                                </td>
                                <th>
                                    {ue}
                                </th>
                                <td>
                                    {ueInputItems && ueInputItems.find(({ value }) => value === ue)?.label}
                                </td>
                            </tr>
                        )
                    }
                    shortRowsFn={
                        ue => (
                            <tr
                                key={`shortue${ue}`}
                            >
                                <td>
                                    <div className="row">
                                        <div className="col-auto">
                                            <button
                                                className="btn btn-outline-secondary col"
                                                onClick={() => deleteRelUeUsr(ue)}
                                            >
                                                &#10007;
                                            </button>
                                        </div>
                                        <InfoText
                                            label="Ue"
                                            text={ue}
                                            className="col-auto"
                                        />
                                        <InfoText
                                            label="Descripción"
                                            text={ueInputItems && ueInputItems.find(({ value }) => value === ue)?.label}
                                            className="col"
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                />
            </div>

        </div>
    )
}
