
import ExcelJS from 'exceljs';

export const saveData = (blob, fileName) => {
    const a = document.createElement("a");
    a.style = "display: none";
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.dispatchEvent(
        new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
    );
    document.body.removeChild(a);
};


export const goXls = async (fileName, createXLS = async (workbook) => { }) => {
    const workbook = new ExcelJS.Workbook();
    await createXLS(workbook)
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true })
    const blob = new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveData(blob, `${fileName}.xls`);
}