import { getCantidad, validateCelular, validateEmail, validateInterger, validateNotEmpty, validateSelectNotEmpty } from "../helpers/utils";

export const nombre = {
    name: "nombre",
    type: "text",
    label: "Nombre",
    validator: validateNotEmpty,
    okMessage: "correcto!",
    errorMessage: "Capture el nombre"
};

export const paterno = {
    name: "paterno",
    type: "text",
    label: "Paterno",
    validator: validateNotEmpty,
    okMessage: "correcto!",
    errorMessage: "Capture el apellido paterno"
};

export const materno = {
    name: "materno",
    type: "text",
    label: "Materno",
    validator: validateNotEmpty,
    okMessage: "correcto!",
    errorMessage: "Capture el apellido materno"
};

export const celular = {
    name: "celular",
    type: "tel",
    label: "Celular",
    validator: validateCelular,
    okMessage: "correcto!",
    errorMessage: "Capture el celular"
};

export const email = {
    name: "email",
    type: "email",
    label: "Email",
    validator: validateEmail,
    okMessage: "correcto!",
    errorMessage: "Capture el email"
};

export const telefono = {
    name: "telefono",
    type: "text",
    label: "Telefono",
    validator: validateCelular,
    okMessage: "correcto!",
    errorMessage: "Capture el telefono"
};

export const direccion = {
    name: "direccion",
    type: "text",
    label: "Direccion",
    validator: validateNotEmpty,
    okMessage: "correcto!",
    errorMessage: "Capture la dirección"
}

export const fechaNacimiento = {
    name: "fechaNacimiento",
    type: "date",
    label: "Fecha Nacimiento",
    validator: validateNotEmpty,
    okMessage: "correcto!",
    errorMessage: "Capture la fecha nacimiento"
}


export const sexo = {
    name: "sexo",
    type: "select",
    label: "Sexo",
    validator: validateSelectNotEmpty,
    okMessage: "correcto!",
    errorMessage: "Capture el sexo",
    selectItems: [
        { value: '', label: 'Sin seleccionar' },
        { value: 'Hombre', label: 'Hombre' },
        { value: 'Mujer', label: 'Mujer' }
    ]
}

export const justificacion = {
    name: "justificacion",
    type: "textarea",
    label: "Justificación del proyecto",
    validator: validateNotEmpty,
}

export const objetivo = {
    name: "objetivo",
    type: "hidden",
    label: "Descripción de bienes y servicios requeridos para el proyecto",
    defaultValue: "N/A",
    validator: validateNotEmpty,
}

export const tipo = {
    name: "tipo",
    type: "select",
    label: "Tipo de proyecto",
    validator: validateSelectNotEmpty,
    okMessage: "correcto!",
    errorMessage: "Capture el tipo",
    selectItems: [
        { value: 'Proyecto de nueva gestión', label: 'Proyecto de nueva gestión' },
        { value: 'Proyecto existente', label: 'Proyecto existente' },
        { value: 'Compra consolidada ', label: 'Compra consolidada ' }
    ]
}

export const costo = {
    name: "costo",
    type: "prorrateo",
    label: "Costo c/Iva",
    validator: value => validateInterger(getCantidad(value)),
}

export const partida = {
    name: "partida",
    type: "hidden",
    label: "Partida",
    validator: () => true,
}

export const actividadAccion = {
    name: "actividadAccion",
    type: "text",
    label: "Detalle específico de cada bien o servicio",
    validator: validateNotEmpty,
}

export const cantidad = {
    name: "cantidad",
    type: "number",
    label: "Cantidad a solicitar",
    validator: validateNotEmpty,
}
