import { DataListManager } from "../helpers/DataListManager";
import { fetchArticulos } from "../helpers/restCalls";
import { validateNotEmpty } from "../helpers/utils";

export const articuloDataListManager = new DataListManager(
    'articulo',
    ({ id, articulo, unidad }) => ({ id: id, value: articulo, label: unidad }),
    fetchArticulos
);

/** name, type, label, validatorator, okMessage,errorMessage */
export const articuloDescriptor =
{
    name: "articulo",
    type: "text",
    label: "Artículo",
    validator: validateNotEmpty,
    okMessage: "correcto!",
    errorMessage: "Capture el artículo",
    getDataList: articuloDataListManager.getDataList
};


