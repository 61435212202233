import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useForm } from '../../hooks/useForm';
import { validateEmail } from '../../helpers/utils';
import { permisosData } from '../../permissions/permisosData';
import { Input } from '../uiComponents/Input';
import { Select } from '../uiComponents/Select';
import { ErrorAlert } from '../uiComponents/ErrorAlert';
import { startNewUser, startUpdateUsuario } from '../../actions/um';


const defaultUserData = {
    nombre: '',
    correo: '',
    password: '',
    rol: 'USUARIO_PRESUPUSTO_EGRESOS',
    permiso: 2
};


export const NewUserScreen = () => {

    const dispatch = useDispatch();
    const { ui, um, auth } = useSelector(state => state);
    const { loading, error } = ui;
    const { selectUser } = um;
    const [formUserValues, handleUserInputChange] = useForm({ ...defaultUserData, ...selectUser });
    const { nombre, correo, permiso, password } = formUserValues;

    const isUserValid = !!nombre && validateEmail(correo) && !!permiso;

    const handleUserSubmit = e => {
        e.preventDefault();
        if (selectUser?.id) {
            dispatch(startUpdateUsuario(
                selectUser.id,
                {
                    ...formUserValues,
                    permiso: Number(permiso)
                }, auth));

        } else {
            dispatch(startNewUser({
                ...formUserValues,
                permiso: Number(permiso)
            }, auth));
        }
    }

    return (
        <div>
            <h3>Manejo usuarios</h3>
            <ErrorAlert error={error} />
            <form onSubmit={handleUserSubmit}>
                <Input
                    name="nombre"
                    type="text"
                    label="nombre"
                    valid={!!nombre}
                    okMessage="correcto!"
                    errorMessage="Capture el nombre"
                    value={nombre}
                    onChange={handleUserInputChange}
                    disabled={loading}
                />

                <Input
                    name="correo"
                    type="correo"
                    label="correo"
                    valid={validateEmail(correo)}
                    okMessage="correcto!"
                    errorMessage="Capture el correo"
                    value={correo}
                    onChange={handleUserInputChange}
                    disabled={loading}
                />

                <Select
                    label="Perfil"
                    name="permiso"
                    value={permiso}
                    onChange={handleUserInputChange}
                    disabled={loading}
                    ariaLabel="Afinidad politica">
                    {
                        permisosData && (
                            permisosData.map(
                                ({ permiso, label }) => (
                                    <option key={`opperfil-${permiso}`} value={permiso}>{label}</option>
                                )
                            )
                        )
                    }
                </Select>

                <Input
                    name="password"
                    type="password"
                    label="password"
                    valid={!!password && password.length > 5}
                    okMessage="correcto!"
                    errorMessage="El password debe de ser más de 6 letras"
                    value={password}
                    onChange={handleUserInputChange}
                    disabled={loading}
                />

                <div className="row mb-5">
                    <div className="col">
                        <Link className="btn btn-secondary btn-block" to={`./userManajament`} >
                            {formUserValues.id ? 'Volver' : 'Cancelar'}
                        </Link>
                    </div>
                    <div className="col">
                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={!isUserValid || loading}
                        >
                            {formUserValues.id ? 'Modificar' : 'Agregar'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

