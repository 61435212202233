import { fetchUes } from "../helpers/restCalls";
import { validateSelectNotEmpty } from "../helpers/utils";

const fillSelectItems = (setter, auth, dispatch) => {
    fetchUes(auth, dispatch, ({ results }) => {
        console.log();
        setter(
            results
                .sort((a, b) => Number(a.ue) - Number(b.ue))
                .map(({ ue, label }) => ({ value: ue, label: `${ue}- ${label}` }))
        );
    })


    // let query = db.collection('ues');
    // if (permiso > 1 && uesUsrData) {
    //     const uri = `${Math.min(...uesUsrData)}`;
    //     const urf = `${Math.max(...uesUsrData)}`;
    //     query = query.where('ue', '>=', uri).where('ue', '<=', urf);
    // }
    // fetchData(query, items =>
    //     setter(
    //         items
    //             .filter(({ ue }) => permiso === 1 || uesUsrData.includes(ue))
    //             .map(({ ue, label }) => ({ value: ue, label: `${ue}- ${label}` }))
    //     )
    // );
}

export const ueDescriptor = {
    name: "ue",
    type: "select",
    label: "Unidad Ejecutora Solicitante",
    validator: validateSelectNotEmpty,
    okMessage: "correcto!",
    errorMessage: "Capture la Unidad Ejecutora",
    fillSelectItems
}