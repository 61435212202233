import { types } from "../types/types";
import { addItemData } from '../helpers/actionHelpers';
import { altaArticulosProyectos, altaProyectos, updateArticulosProyectos, updateProyectos } from "../helpers/restCalls";

export const addCadenaData =
    (cadenaData, beforeUpdate, beforeSelect) => addItemData('cadenas', cadenaData, selectCadena, undefined, beforeUpdate, beforeSelect);


export const selectCadena = cadenaData => ({
    type: types.appSelectCadenaData,
    payload: cadenaData
});

export const clearSelectCadenaData = () => ({
    type: types.appClearSelectCadenaData
});

export const selectProyecto = proyectoData => {
    return ({
    type: types.appSelectProyectoData,
    payload: proyectoData
})
};

export const clearSelectProyectoData = () => ({
    type: types.appClearSelectProyectoData
});

export const addProyecto =
    (proyectoData, validate, beforeUpdate, beforeSelect, afterUpdate) => addItemData(
        async ({ auth }, dispatch, itemData) => await altaProyectos(auth, itemData, dispatch),
        async ({ auth }, dispatch, id, itemData) => await updateProyectos(auth, id, itemData, dispatch),
        proyectoData, selectProyecto, selectProyecto, validate, beforeUpdate, beforeSelect, afterUpdate);


export const onInsertDetalleProyecto = proyectoData => ({
    type: types.appOnInsertDetalleProyecto,
    payload: proyectoData
});

export const onUpdateDetalleProyecto = proyectoData => ({
    type: types.appOnUpdateDetalleProyecto,
    payload: proyectoData
});


export const clearSelectDetalleProyectoData = () => ({
    type: types.appClearDetalleSelectProyectoData
});

export const addDetalleProyecto =
    (detalleProyectoData, validate, beforeUpdate, beforeSelect, afterUpdate) => addItemData(
        async ({ auth, app }, dispatch, itemData) => await altaArticulosProyectos(auth, app.selectedProyectoData.id, itemData, dispatch),
        async ({ auth, app }, dispatch, id, itemData) => await updateArticulosProyectos(auth, app.selectedProyectoData.id, id, itemData, dispatch),
        detalleProyectoData, onInsertDetalleProyecto, onUpdateDetalleProyecto, validate, beforeUpdate, beforeSelect, afterUpdate
    );

export const altaDetalleProyecto = () => ({
    type: types.appAltaDetalleProyecto
});

export const updateDetalleProyecto = proyectoData => ({
    type: types.appUpdateDetalleProyecto,
    payload: proyectoData
});

export const deleteDetalleProyecto = id => ({
    type: types.appDeleteDetalleProyecto,
    payload: id
});