import { types } from "../types/types";


const initialState = {
}

export const appReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case types.appSelectCadenaData:
            return {
                ...state,
                selectedCadenaData: action.payload
            }
        case types.appClearSelectCadenaData:
            newState = { ...state };
            delete newState.selectedCadenaData;
            return newState;
        case types.appSelectProyectoData:
            return {
                ...state,
                selectedProyectoData: action.payload
            }
        case types.appClearSelectProyectoData:
            newState = { ...state };
            delete newState.selectedProyectoData;
            return newState;
        case types.appSelectDetalleProyectoData:
            return {
                ...state,
                selectedDetalleProyectoData: action.payload
            }
        case types.appOnInsertDetalleProyecto:
            newState = { ...state }
            delete newState.selectedDetalleProyectoData;
            newState.selectedProyectoData.articulos.push(action.payload);
            return {
                ...state,
                selectedProyectoData: { ...newState.selectedProyectoData },
                selectedDetalleProyectoData:action.payload
            }
        case types.appOnUpdateDetalleProyecto:
            newState = { ...state }
            delete newState.selectedDetalleProyectoData;
            newState.selectedProyectoData.articulos[newState.selectedProyectoData.articulos.findIndex(({ id }) => action.payload.id === id)] = action.payload;
            return {
                ...state,
                selectedProyectoData: { ...newState.selectedProyectoData },
                selectedDetalleProyectoData:action.payload
            }
        case types.appClearDetalleSelectProyectoData:
            if (state.selectedDetalleProyectoData) {
                newState = { ...state };
                delete newState.selectedDetalleProyectoData;
                return newState;
            }
            break;
        case types.appAltaDetalleProyecto:
            newState = { ...state };
            delete newState.selectedDetalleProyectoData;
            return newState;
        case types.appUpdateDetalleProyecto:
            return {
                ...state,
                selectedDetalleProyectoData: action.payload
            }
        case types.appDeleteDetalleProyecto:
            newState = { ...state }
            newState.selectedProyectoData.articulos = newState.selectedProyectoData.articulos.filter(({ id }) => action.payload !== id);
            return newState;
        default:
            return state;
    }
}
