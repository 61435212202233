import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { clearSelectProyectoData, selectProyecto } from '../../actions/app';
import { clearError } from '../../actions/ui';

import { goXls } from '../../helpers/ExcelUtils';
import { fetchProyectos } from '../../helpers/restCalls';
import { capitalize, formatCurrency, getCantidad, processError } from '../../helpers/utils';
import { useLocalTaskStatus } from '../../hooks/useLocalTaskStatus';
import { ErrorAlert } from '../uiComponents/ErrorAlert';
import { InfoText } from '../uiComponents/InfoText';
import { Loading } from '../uiComponents/Loading';
import { Table } from '../uiComponents/Table';


export const MainScreen = ({ history }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { auth } = useSelector(state => state);
    const [requisicionesData, setRequisicionesData] = useState();
    const [error, loading, setError, setLoading] = useLocalTaskStatus();

    useEffect(() => {
        let mounted = true;
        fetchProyectos(
            auth,
            dispatch,
            ({ results, total }) => {
                mounted && setRequisicionesData(results);
            }, error => {
                console.error(error);
                mounted && setError(processError(error));
            }
        );
        return () => {
            mounted = false;
        }
    }, [auth, dispatch, setError, setLoading]);


    const handleAddRequisicion = () => {
        dispatch(clearSelectProyectoData());
        dispatch(clearError());
        history.push('./altaProyectoScreen');
    }

    const handleEditaRequisicion = (requisicionData) => {
        dispatch(selectProyecto(requisicionData));
        dispatch(clearError());
        history.push('./altaProyectoScreen');
    }

    const generaReporte = () => {
        const createRep = async (workbook) => {

            const worksheet = workbook.addWorksheet("My Sheet");
            const style = { numFmt: '"$"#,##0.00;[Red]\\-"$"#,##0.00' };
            const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

            ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'V1'].forEach(cell => {
                worksheet.getCell(cell).font = { name: 'Calibri', bold: true };
                worksheet.getCell(cell).fill = {
                    type: 'pattern',
                    pattern: 'darkTrellis',
                    fgColor: { argb: 'FFFFFF00' },
                    bgColor: { argb: 'FFFFF200' }
                };
            });

            ['J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1', 'T1', 'U1'].forEach(cell => {
                worksheet.getCell(cell).font = { name: 'Calibri', bold: true };
                worksheet.getCell(cell).fill = {
                    type: 'pattern',
                    pattern: 'darkTrellis',
                    fgColor: { argb: 'FF00FF00' },
                    bgColor: { argb: 'FFADD58A' }
                };
            });

            worksheet.columns = [
                { header: 'Justificación', key: 'justificacion', width: 30, },
                { header: 'Descripción de bienes y servicios requeridos para el proyecto', key: 'objetivo', width: 30, },
                { header: 'Tipo.', key: 'tipo', width: 25, },
                { header: 'UE.', key: 'ue', width: 5, },
                { header: 'Articulo.', key: 'articulo', width: 25, },
                { header: 'Cantidad.', key: 'cantidad', width: 25, },
                { header: 'Detalle específico de cada bien o servicio', key: 'actividadAccion', width: 25, },
                { header: 'Partida.', key: 'partida', width: 8, },
                { header: 'Costo.', key: 'costo', width: 15, style },
                ...meses.map(mes => ({ header: capitalize(mes), key: mes, width: 15, style })),
                { header: 'Capturo', key: 'nombre', width: 15, style },
            ];
            requisicionesData.forEach(({ articulos, ...requisicionData }) =>
                articulos.forEach(({ costo, ...articulo }) => {
                    const row = { ...requisicionData, ...articulo, costo: getCantidad(costo), nombre:requisicionData.usuario.nombre };
                    meses.forEach((mes, idx) => row[mes] = costo[idx]);
                    worksheet.addRow(row);
                }));

            worksheet.views = [
                { state: 'frozen', xSplit: 0, ySplit: 1 }
            ];

        };
        goXls(`Reporte.${new Date().toISOString()}`, createRep);
    }


    return (
        <div key={'main' + location.key} className="content animate__animated animate__flip">
            <h2>Proyecto para el Anteproyecto Presupuesto Egresos 202 
                {/* <button className="btn btn-sm btn-outline-dark " onClick={handleAddRequisicion} > + </button> */}
            </h2>

            <Loading loading={loading} />
            <ErrorAlert error={error} />
            <button className="btn btn-secondary" onClick={generaReporte}>XLS</button>

            <Table
                titles={['', 'Justificación', 'Tipo', 'UE', 'Monto']}
                rowsData={requisicionesData}
                rowsFn={
                    requisicionData => (
                        <tr
                            key={`ca${requisicionData.id}`}
                        >
                            <td>
                                {/* <button
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() => handleEditaRequisicion(requisicionData)}
                                >
                                    &#9999;
                                </button> */}
                            </td>
                            <th>
                                {requisicionData.justificacion}
                            </th>
                            <td >
                                {requisicionData.tipo}
                            </td>
                            <td >
                                {requisicionData.ue}
                            </td>
                            <td>
                                {
                                    formatCurrency(
                                        requisicionData.articulos
                                            .reduce(
                                                (acumulador, { costo, cantidad }) =>
                                                    acumulador + costo.reduce((ac, v) => ac + v, 0), 0)
                                    )
                                }
                            </td>

                        </tr>
                    )
                }
                shortRowsFn={
                    requisicionData => (
                        <tr
                            key={`short${requisicionData.id}`}
                        >
                            <td>
                                <div className="row">
                                    <div className="col-auto">
                                        <button
                                            className="btn btn-outline-secondary col"
                                            onClick={() => handleEditaRequisicion(requisicionData)}
                                        >
                                            &#9999;
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <InfoText
                                        label="Justificación"
                                        text={requisicionData.justificacion}
                                        className="col"
                                    />
                                </div>
                                <div className="row">
                                    <InfoText
                                        label="Tipo"
                                        text={requisicionData.tipo}
                                        className="col"
                                    />
                                </div>

                                <div className="row">
                                    <InfoText
                                        label="UE"
                                        text={requisicionData.ue}
                                        className="col"
                                    />
                                </div>
                            </td>
                        </tr>
                    )
                }
            />


        </div>
    )
}
