
import { types } from '../types/types';

const initialState = {
    loading: false,
    isOnUpdate: false,
    error: null
}

export const uiReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.uiStartLoading:
            return {
                ...state,
                loading: true,
                error: null
            }

        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }

        case types.uiError:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case types.uiClearError:
            return {
                ...state,
                error: null
            }

        case types.uiClearState:
            return {
                ...initialState
            };

        case types.uiStartUpdate:
            return {
                ...state,
                isOnUpdate: true
            }

        case types.uiFinishUpdate:
            return {
                ...state,
                isOnUpdate: false,
                loading: false
            }

        case types.uiInputItems:
            return {
                ...state,
                [`${action.payload.name}InputItems`]: action.payload.inputItems,
            }

        default:
            return state;
    }

}