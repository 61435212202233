import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addProyecto, altaDetalleProyecto, updateDetalleProyecto, deleteDetalleProyecto } from '../../actions/app';
import { proyectoDescriptor } from '../../descriptors/proyectoDescriptor';
import { formatCurrency, getDefaultStateData, getCantidad, nomalizeId } from '../../helpers/utils';

import { useLocalTaskStatus } from '../../hooks/useLocalTaskStatus';
import { InfoText } from '../uiComponents/InfoText';
import { Table } from '../uiComponents/Table';
import { UpdateScreen } from '../uiComponents/UpdateScreen';
import Swal from 'sweetalert2';
import { Loading } from '../uiComponents/Loading';
import { ErrorAlert } from '../uiComponents/ErrorAlert';
import { deleteArticulosProyectos } from '../../helpers/restCalls';


export const AltaProyectoScreen = ({ history }) => {

    const dispatch = useDispatch();

    const { auth, ui, app } = useSelector(state => state);
    const { loading, error } = ui;
    const {
        selectedProyectoData = getDefaultStateData(proyectoDescriptor)
    } = app;

    const [lError, lLoading] = useLocalTaskStatus();

    const handleInsertaArticulo = useCallback(() => {
        dispatch(altaDetalleProyecto());
        history.push('/altaArticuloScreen');
    }, [dispatch, history]);

    const handleEditaArticulo = useCallback(proyectoData => {
        dispatch(updateDetalleProyecto(proyectoData));
        history.push('/altaArticuloScreen');
    }, [dispatch, history]);

    const handleBorraArticulo = useCallback(articuloData => {
        Swal.fire({
            title: `¿Desea borrar el artículo (${articuloData.articulo}) realmente?`,
            showCancelButton: true,
            confirmButtonText: `Borrar`,
            cancelButtonText: `Cancelar`,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                deleteArticulosProyectos(auth, selectedProyectoData.id, articuloData.id, dispatch, deleteDetalleProyecto);
            }
        }
        );
    }, [auth,dispatch, selectedProyectoData.id]);

    const afterUpdate = useCallback(data => {
        if (!data.articulos) {
            data.articulos = [];
        }
        return data;
    }, []);

    return (
        <>
            {/* <div className="fade-in" > */}
            <div>
                <h3>
                    {
                        selectedProyectoData
                            ? (
                                <>Modificación de Proyecto</>
                            )
                            : (
                                <>Alta de Proyecto</>
                            )
                    }
                </h3>
                <Loading loading={lLoading} />
                <ErrorAlert error={lError} />
                <UpdateScreen
                    fieldsDescriptor={proyectoDescriptor}
                    selectedData={selectedProyectoData}
                    calcelPath={`./`}
                    loading={loading}
                    error={error}
                    action={addProyecto}
                    afterUpdate={afterUpdate}
                >
                    {
                        selectedProyectoData?.id && (
                            <div className="container m-2 mb-5">
                                <h4>
                                    Bien o Servicio <button
                                        type="button"
                                        className="btn btn-sm btn-outline-dark"
                                        onClick={handleInsertaArticulo}
                                    >
                                        +
                                    </button>
                                </h4>
                                {selectedProyectoData.articulos && (
                                    <Table
                                        titles={['', 'Artículo', 'Cantidad', 'Detalle específico de cada bien o servicio', 'Partida', 'Costo c/Iva']}
                                        rowsData={selectedProyectoData.articulos.map(articulo => nomalizeId(articulo))}
                                        rowsFn={
                                            articuloData => (
                                                <tr
                                                    key={`ca${articuloData.id}`}
                                                >
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary btn-sm"
                                                            onClick={() => handleEditaArticulo(articuloData)}
                                                        >
                                                            &#9999;
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary btn-sm mx-1"
                                                            onClick={() => handleBorraArticulo(articuloData)}
                                                        >
                                                            &#10008;
                                                        </button>
                                                    </td>
                                                    <th>
                                                        {articuloData.articulo}
                                                    </th>
                                                    <th>
                                                        {articuloData?.cantidad}
                                                    </th>
                                                    <th>
                                                        {articuloData.actividadAccion}
                                                    </th>
                                                    <td >
                                                        {articuloData.partida}
                                                    </td>
                                                    <td className="text-end">
                                                        {formatCurrency(getCantidad(articuloData.costo))}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        shortRowsFn={
                                            articuloData => (
                                                <tr
                                                    key={`short${articuloData.id}`}
                                                >
                                                    <td colSpan="8">
                                                        <div className="row">
                                                            <div className="col">
                                                                <InfoText
                                                                    label="Artículo"
                                                                    text={articuloData.articulo}
                                                                />
                                                            </div>
                                                            <div className="col-auto">
                                                                <button
                                                                    className="btn btn-outline-secondary col"
                                                                    onClick={() => handleEditaArticulo(articuloData)}
                                                                >
                                                                    &#9999;
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <InfoText
                                                                label="Cantidad"
                                                                text={articuloData?.cantidad}
                                                                className="col"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <InfoText
                                                                label="Actividad / Acción"
                                                                text={articuloData.actividadAccion}
                                                                className="col"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <InfoText
                                                                label="Partida"
                                                                text={articuloData.partida}
                                                                className="col-auto"
                                                            />
                                                            <InfoText
                                                                label="Costo c/Iva"
                                                                text={formatCurrency(getCantidad(articuloData.costo))}
                                                                className="col"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        footer={(
                                            <tr>
                                                <th className="text-end" colSpan="4"></th>
                                                <th className="text-end">
                                                    <h3>
                                                        {formatCurrency(selectedProyectoData.articulos.reduce((acumulador, { costo }) => acumulador + getCantidad(costo) * 1, 0))}
                                                    </h3>
                                                </th>
                                            </tr>
                                        )}
                                    />
                                )}
                            </div>
                        )
                    }
                </UpdateScreen>
            </div>
        </>
    )
}
