import { types } from "../types/types";

export const startLoading = () => ({
    type: types.uiStartLoading
});

export const finishLoading = () => ({
    type: types.uiFinishLoading
});

export const setErrorMsg = error => ({
    type: types.uiError,
    payload: error
});

export const clearError = () => ({
    type: types.uiClearError
});

export const clearUiState = () => ({
    type: types.uiClearState
});

export const startUpdate = () => ({
    type: types.uiStartUpdate
});

export const finishUpdate = () => ({
    type: types.uiFinishUpdate
});

export const setInputItems = (name, inputItems) => ({
    type: types.uiInputItems,
    payload: { name, inputItems }
});