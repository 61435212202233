import { processError } from '../helpers/utils';
import { types } from '../types/types';
import { startLoading, setErrorMsg, finishLoading, clearError } from './ui';
import { login } from './auth';
import { altaUsuario, updateUsuario } from '../helpers/restCalls';

export const selectUserData = userData => ({
    type: types.umSelectUserData,
    payload: userData
});

export const clearSelectUserData = () => ({
    type: types.umClearSelectUserData
});

export const startNewUser = (body, auth) => {
    return async (dispatch) => {
        dispatch(clearError());
        try {
            const usuario = await altaUsuario(auth, body, dispatch);
            dispatch(selectUserData(usuario));
        } catch (error) {
            console.error(error);
            dispatch(setErrorMsg(processError(error)));
            dispatch(selectUserData(body));
        }
    }
}


export const startUpdateUsuario = (id, body, auth) => {
    return async (dispatch) => {
        dispatch(clearError());
        dispatch(selectUserData(body));
        try {
            await updateUsuario(id, auth, body, dispatch);
        } catch (error) {
            console.error(error);
            dispatch(setErrorMsg(processError(error)));
        }
    }
}

export const singUpEmail = (userData, email, password) => {

    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const { user } = {};//get User Data
            const { uid, displayName } = user;
            const { id, ...rest } = userData;
            rest.userUid = uid;
            rest.estado = 1;
            //await db.doc(`usuarios/${id}`).update(rest);
            const newUserData = {
                uid,
                displayName,
                ...rest
            };
            dispatch(login(newUserData));
            dispatch(finishLoading());
        }
        catch (error) {
            console.error(error);
            dispatch(setErrorMsg(processError(error)));
        }
    }

}
