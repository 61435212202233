import React from 'react'

export const Table = ({
    titles,
    shortTitles = 'Información',
    rowsData,
    rowsFn = () => null,
    shortRowsFn = () => null,
    footer
}) => {
    return (
        <>
            <div className="d-md-none">
                <table className="table table-striped table-hover">
                    {
                        shortTitles && (
                            <thead>
                                <tr>
                                    <th>
                                        {shortTitles}
                                    </th>
                                </tr>
                            </thead>
                        )
                    }
                    <tbody>
                        {
                            rowsData && rowsData.map(shortRowsFn)
                        }
                    </tbody>
                    {
                        footer && (
                            <tfoot>
                                {footer}
                            </tfoot>
                        )
                    }
                </table>
            </div>
            <div className="d-none d-md-block">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            {
                                titles && titles.map((title, idx) => (
                                    <th key={`${idx}-${title}`}>{title}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rowsData && rowsData.map(rowsFn)
                        }
                    </tbody>
                    {
                        footer && (
                            <tfoot>
                                {footer}
                            </tfoot>
                        )
                    }
                </table>
            </div>
        </>
    )
}
