import React from 'react'

export const InfoText = ({ label, text, className = '' }) => {
    return (
        <div className={className}>
            <h6>
                {label} <small className="text-muted">
                    {text}
                </small>
            </h6>
        </div>
    )
}
