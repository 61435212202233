

export const types = {

    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiStartLoading: '[UI] Start Loading',
    uiFinishLoading: '[UI] Finish Loading',
    uiStartUpdate: '[UI] Start Update',
    uiFinishUpdate: '[UI] Finish Update',

    uiError: '[UI] Error message',
    uiClearError: '[UI] Clear Error message',
    uiClearState: '[UI] Clear State',

    uiInputItems: '[UI] Input Items',

    umSelectUserData: '[UM] Select User Data',
    umClearSelectUserData: '[UM] Clear Select User Data',

    umSelectZoneData: '[UM] Select Zone Data',
    umClearSelectZoneData: '[UM] Clear Select Zones Data',

    appSelectCadenaData: '[APP] Select Cadena Data',
    appClearSelectCadenaData: '[APP] Clear Cadena Data',

    appSelectProyectoData: '[APP] Select Proyecto Data',
    appClearSelectProyectoData: '[APP] Clear Proyecto Data',

    appOnInsertDetalleProyecto: '[APP] On Insert Detalle Proyecto',
    appOnUpdateDetalleProyecto: '[APP] On Update Detalle Proyecto',

    appClearDetalleSelectProyectoData: '[APP] Clear Detalle Proyecto Data',

    appAltaDetalleProyecto: '[APP] Alta Detalle Proyecto',
    appUpdateDetalleProyecto: '[APP] Update Detalle Proyecto',

    appDeleteDetalleProyecto: '[APP] Delete Detalle Proyecto',


}