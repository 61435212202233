import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setInputItems } from '../../actions/ui';
import { ProrrateoInput } from './ProrrateoInput'

export const Input = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const {
        name,
        label,
        prepend,
        valid,
        errorMessage,
        okMessage,
        value,
        disabled = false,
        validator,
        className = '',
        autoComplete = 'off',
        getDataList,
        type,
        selectItems,
        fillSelectItems,
        setValues,
        defaultValue,
        ...rest
    } = props;
    const inputProps = { id: name, name, value, disabled, autoComplete, type, ...rest }
    const selectProps = { id: name, name, value, disabled, ...rest }
    const checkInlineProps = { name, disabled, ...rest }

    const [dataList, setDataList] = useState(getDataList ? getDataList() : undefined);

    if (getDataList) {
        inputProps['autoComplete'] = 'off';
        inputProps['list'] = `${inputProps.id}-dataList`;
        const uOnKeyUp = inputProps.onKeyUp;
        inputProps.onKeyUp = e => {
            getDataList(setDataList, e.target.value, auth, dispatch);
            if (uOnKeyUp) {
                uOnKeyUp(e);
            }
        }
    }
    const isValid = () =>
        validator
            ? validator(value, dataList)
            : valid === undefined
                ? true
                : valid;


    const [lSselectItems, setLSselectItems] = useState();
    const { [`${name}InputItems`]: inputItems } = useSelector(state => state.ui);

    useEffect(() => {
        let isMounted = true;
        if (!selectItems && fillSelectItems) {
            if (inputItems) {
                setLSselectItems(inputItems);
            } else {
                fillSelectItems(inputItems => {
                    if (isMounted) {
                        dispatch(setInputItems(name, inputItems));
                        setLSselectItems(inputItems);
                    }
                }, auth, dispatch);
            }
        }
        return () => {
            isMounted = false;
        }
    }, [selectItems, fillSelectItems, setLSselectItems, dispatch, inputItems, name, auth])


    return (
        <div className="mb-3">
            {
                type !== 'hidden' &&
                <label htmlFor={name} className={`form-label ${type === 'checkInline' ? 'h5' : ''}`} >{label}</label>
            }
            <div className="input-group has-validation">
                {
                    prepend && (<span className="input-group-text" id={`${name}InputGroupPrepend`}>{prepend}</span>)
                }
                {
                    type === 'select'
                        ? (

                            <select
                                className={`form-select ${isValid() ? 'is-valid' : 'is-invalid'} ${className}`}
                                aria-label={`Seleccione ${label}`}
                                {...selectProps}
                            >
                                {
                                    selectItems && selectItems.map(({ value, label }) => (
                                        <option key={`sel-${name}+${value}`} value={value}>{label}</option>
                                    ))
                                }
                                {
                                    lSselectItems && (
                                        <>
                                            <option></option>
                                            {
                                                lSselectItems.map(({ value, label }) => (
                                                    <option key={`sel-${name}+${value}`} value={value}>{label}</option>
                                                ))
                                            }
                                        </>
                                    )
                                }
                                {
                                    !selectItems && !lSselectItems && (
                                        <option>Cargando...</option>
                                    )
                                }
                            </select>
                        )
                        : type === 'checkInline'
                            ? (
                                <div className="border rounded-pill px-4 pr-1 py-1 shadow ml-4 mr-1">
                                    {selectItems && selectItems.map(({ value: valueIt, label }) => (
                                        <div key={`ckInLn-${name}+${valueIt}`} className="form-check form-check-inline form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`id-ckInLn-${name}+${valueIt}`}
                                                value={valueIt}
                                                checked={value && !!value.split(',').find(valueSp => valueSp === valueIt)}
                                                {...checkInlineProps}
                                            />
                                            <label className="form-check-label" htmlFor={`id-ckInLn-${name}+${valueIt}`}>{label}</label>
                                        </div>
                                    ))}
                                </div>
                            )
                            : type === 'textarea'
                                ? (
                                    <textarea
                                        className={`form-control ${isValid() ? 'is-valid' : 'is-invalid'} ${className}`}
                                        aria-label={`Captura de ${name}`}
                                        {...inputProps}
                                    >
                                    </textarea>
                                )
                                : type === 'prorrateo'
                                    ? (
                                        <ProrrateoInput
                                            className={`form-control ${isValid() ? 'is-valid' : 'is-invalid'} ${className}`}
                                            aria-describedby={`${name}InputGroupPrepend ${name}Feedback`}
                                            setFormValues={setValues}
                                            {...inputProps}
                                        />
                                    )
                                    : (
                                        <input
                                            className={`form-control ${isValid() ? 'is-valid' : 'is-invalid'} ${className}`}
                                            aria-describedby={`${name}InputGroupPrepend ${name}Feedback`}
                                            {...inputProps}
                                        />
                                    )
                }

                {
                    dataList && (
                        <datalist id={inputProps.list}>
                            {
                                dataList.map(({ id, value, label }) => (
                                    <option key={`${inputProps.list}-op-${id}`} value={value} >
                                        {label}
                                    </option>
                                ))
                            }
                        </datalist>
                    )
                }
                <div className="valid-feedback">
                    {
                        dataList && isValid() ? dataList.find(it => it.value === value)?.label : okMessage
                    }
                </div>
                <div id={`${name}Feedback`} className="invalid-feedback">
                    {errorMessage}
                </div>
            </div>

        </div>
    )
}
