import React from 'react'

export const Select = (
    {
        name,
        label,
        prepend,
        value,
        onChange,
        disabled = false,
        children,
        ariaLabel = '',
        localSaveCancel,
        ...rest
    }
) => (
    <div className="mb-3">
        <label htmlFor={name} className="form-label">{label}</label>
        <div className="input-group">
            {
                prepend && (<span className="input-group-text" id={`${name}InputGroupPrepend`}>{prepend}</span>)
            }
            <select
                name={name}
                id={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className="form-control form-select"
                aria-label={ariaLabel}
                {...rest}
            >
                {children}
            </select>
            {
                localSaveCancel && (
                    <>
                        <button
                            className="btn btn-outline-secondary display-6"
                            type="button"
                            id={`button-addon-${name}-ok`}
                            onClick={localSaveCancel.onSave}
                        >
                          <b>&#10003;</b>
                        </button>
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id={`button-addon-${name}-cancel`}
                            onClick={localSaveCancel.onCancel}
                        >
                            &#10008;
                        </button>
                    </>
                )
            }
        </div>
    </div >
)
