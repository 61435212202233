import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import {
    BrowserRouter as Router,
    Redirect,
    Switch
} from "react-router-dom";

import { LoginScreen } from '../components/auth/LoginScreen'
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { InternalRoters } from './InternalRoters';
import { SingUpScreen } from '../components/auth/SingUpScreen';
import { useSelector } from 'react-redux';
import { login } from '../actions/auth';

export const AppRouter = () => {

    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);

    const [cheking, setCheking] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const appData = window.localStorage.getItem('appData');
        if (appData) {
            dispatch(login(JSON.parse(appData)));
        }
    }, [dispatch])


    useEffect(() => {
        setIsLoggedIn(!!auth.token);
        setCheking(false);
    }, [auth.token, setCheking, setIsLoggedIn]);

    if (cheking) {
        return (
            <div className="container m-0 m-sm-3">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            <strong>Cargando...</strong>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <Router>
                    <div>
                        <Switch>
                            <PublicRoute
                                exact
                                path="/login"
                                component={LoginScreen}
                                isAuthenticated={isLoggedIn}
                            />
                            <PublicRoute
                                exact
                                path="/singup/:userUid"
                                component={SingUpScreen}
                                isAuthenticated={isLoggedIn}
                            />
                            <PrivateRoute
                                path="/"
                                component={InternalRoters}
                                isAuthenticated={isLoggedIn}
                            />
                            <Redirect to="/login" />
                        </Switch>
                    </div>
                </Router>
            </div>
        )
    }
}
