import React from 'react'
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import { ErrorAlert } from './ErrorAlert';
import { Input } from './Input';

export const UpdateScreen = ({
    fieldsDescriptor,
    selectedData,
    calcelPath,
    loading,
    error,
    action,
    children,
    history,
    validate,
    beforeUpdate,
    beforeSelect,
    afterUpdate,
    onCancelClick,
    labelsCancel = { onIdExists: 'Volver', onIdNotExists: 'Cancelar' },
    onChange
}) => {

    const dispatch = useDispatch();
    const [formValues, handleInputChange, setValues] = useForm(selectedData, onChange);
    const { id } = selectedData;

    const isFieldsValid = () =>
        fieldsDescriptor.reduce((valid, { name, validator }) => valid && (!validator || validator(formValues[name])), true);


    const handleSubmit = e => {
        console.log('handleSubmit');
        e.preventDefault();
        dispatch(action({
            id, ...formValues
        }, validate, beforeUpdate, beforeSelect, afterUpdate));
    }

    return (

        <form onSubmit={handleSubmit}>
            {
                fieldsDescriptor.map(
                    fd => (
                        <Input
                            key={fd.name}
                            value={formValues[fd.name]}
                            onChange={handleInputChange}
                            disabled={fd.loading}
                            setValues={setValues}
                            {...fd}
                        />
                    )
                )
            }
            {children}
            <ErrorAlert error={error} />
            <div className="row mb-5">
                <div className="col d-grid gap-2">
                    {
                        labelsCancel[id ? 'onIdExists' : 'onIdNotExists'] && (calcelPath
                            ? (
                                <Link className="btn btn-secondary btn-block" to={calcelPath} >
                                    { labelsCancel[id ? 'onIdExists' : 'onIdNotExists']}
                                </Link>
                            )
                            : history
                                ? (
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        onClick={() => history.goBack()}
                                    >
                                        { labelsCancel[id ? 'onIdExists' : 'onIdNotExists']}
                                    </button>
                                )
                                : onCancelClick && (
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        onClick={onCancelClick}
                                    >
                                        { labelsCancel[id ? 'onIdExists' : 'onIdNotExists']}
                                    </button>
                                )
                        )
                    }

                </div>
                <div className="col d-grid gap-2">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading || !isFieldsValid()}
                    >
                        {id ? 'Guardar' : 'Agregar'}
                    </button>
                </div>
            </div>
        </form>
    )
}
