import { combineReducers } from 'redux'
import { appReducer } from './appReducer'
import { authReducer } from './authReducer'
import { uiReducer } from './uiReducer'
import { umReducer } from './umReducer'

export const rootReducer = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    um: umReducer,
    app: appReducer
})