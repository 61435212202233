import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom';
import { startLogout } from '../../actions/auth';
import { componentPermissionsMap } from '../../permissions/componentPermissionsMap';
import { permisosData } from '../../permissions/permisosData';

export const Navbar = () => {

    const dispatch = useDispatch();
    const { nombre, permiso } = useSelector(state => state.auth);

    const handleLogout = () => {
        dispatch(startLogout());
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <NavLink activeClassName="active" className="navbar-brand" to="/" exact>
                    Anteproyecto Presupuesto Egresos 2024
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {
                            permisosData
                                .filter(permisoData => permisoData.menu && permisoData.permiso >= permiso)
                                .map(permisoData => (
                                    <li key={permisoData.menu} className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle" href="#top" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                                            onClick={() => true}
                                        >
                                            {permisoData.menu}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {
                                                componentPermissionsMap
                                                    .filter(({ menu, permiso }) => menu && permiso === permisoData.permiso)
                                                    .map(({ path, menu }) => (
                                                        <li key={`${menu}.${path}`}>
                                                            <NavLink activeClassName="active" className="dropdown-item" to={path} exact>
                                                                {menu}
                                                            </NavLink>
                                                        </li>
                                                    ))
                                            }
                                        </ul>
                                    </li>
                                ))
                        }
                    </ul>
                    <div className="form-inline">
                        <span className="navbar-text mx-3">
                            {nombre}
                        </span>
                        <button className="btn btn-outline-success my-2 my-sm-0" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt"></i>
                            <span> Salir</span>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    )
}
