
export class DataListManager {
    constructor(
        field,
        itemToDataListMapper,
        query
    ) {
        this.dataList = [];
        this.field = field;
        this.itemToDataListMapper = itemToDataListMapper;
        this.query = query;
    }

    itemToDataList = items => items.map(this.itemToDataListMapper);

    setDbItems = (setDataList, items) => {
        if (items.length > 0) {
            this.dataList = this.itemToDataList(items);
            setDataList(this.dataList);
        }
    }

    findValueOnDataList = (value, dataList = this.dataList) => dataList && dataList.find(item => item.value === value);

    beforeUpdate = values => {
        const upValues = { ...values };
        upValues[this.field] = this.findValueOnDataList(upValues[this.field]).id;
        return upValues;
    }

    beforeSelect = values => {
        const selValues = { ...values };
        const { id, value } = this.findValueOnDataList(selValues[this.field]);
        selValues[this.field] = { id, value };
        return selValues;
    }

    formatStateData = selectedData => {
        const { id, value } = selectedData[this.field];
        if (id) {
            if (!this.findValueOnDataList(value)) {
                this.dataList.push({ id, value });
            }
            selectedData[this.field] = value;
            return true;
        }
        return false;
    };

    getDataList = (setDataList, value, auth, dispatch) => {
        if (value && setDataList && value.length > 2 && !this.findValueOnDataList(value)) {
            this.query(value, auth, dispatch,
                ({ results }) => {
                    setDataList(results.map(this.itemToDataListMapper));
                },
                console.error);
        }
    }

}