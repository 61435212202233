import Swal from 'sweetalert2';

import { types } from '../types/types';
import { startLoading, finishLoading, clearUiState } from './ui';
import { fetchLogin } from '../helpers/restCalls';

export const startLoginEmailPassword = (correo, password) => {
    return (dispatch) => {
        dispatch(startLoading());
        return fetchLogin(correo, password, dispatch,
            data => {
                if (data.errors) {
                    const message = data.errors.map(({ msg }) => msg).join('<br>');
                    console.error(message);
                    Swal.fire('Error:', message, 'error');
                } if (data.msg) {
                    console.error(data.msg);
                    Swal.fire('Error:', data.msg, 'error');
                } else {
                    const { usuario, token } = data;
                    dispatch(login({ ...usuario, token }));
                }
                dispatch(finishLoading());
            },
            message => {
                dispatch(finishLoading());
                Swal.fire('Error:', message, 'error');
                console.error(message);
            }
        );
    }
}

export const startLogout = () => {
    return async (dispatch) => {        
        window.localStorage.removeItem('appData');
        dispatch(logout());
        dispatch(clearUiState());
    }
}

export const login = userData => {
    window.localStorage.setItem('appData', JSON.stringify(userData));
    return {
        type: types.login,
        payload: userData
    }
};

export const logout = () => ({
    type: types.logout
});