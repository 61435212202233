import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useForm } from '../../hooks/useForm';

import { formatEmail } from '../../helpers/utils';
import { Input } from '../uiComponents/Input';
import { Loading } from '../uiComponents/Loading';
import { ErrorAlert } from '../uiComponents/ErrorAlert';
import { useDispatch, useSelector } from 'react-redux';
import { singUpEmail } from '../../actions/um';

export const SingUpScreen = ({ history }) => {
    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.ui);

    const [userData/*, setUserData*/] = useState();
    const { userUid } = useParams();

    const [formLoginRegisterValues, handleLoginRegisterInputChange] = useForm({
        email: '',
        password: '',
        password2: ''
    });
    const { email, password, password2 } = formLoginRegisterValues;

    useEffect(() => {
        if (userUid) {
            //get user data
        }
    }, [userUid]);

    if (userData === null || userData?.userUID) {
        history.replace('/login')
    }

    const handleSubmitSingIn = e => {
        e.preventDefault();
        dispatch(singUpEmail(userData, email, password));
    }

    const isValidEmail = () => {
        const r = userData && formatEmail(email) === userData.email;
        return r;
    };
    const isValidPassword2 = () => !!password2 && password === password2;

    const isValidLogin = isValidEmail() && !!password && isValidPassword2();

    return (
        <div className="container m-0 m-sm-3">
            <h1>Registro</h1>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmitSingIn}>
                        <Input
                            name="email"
                            type="email"
                            label="email"
                            prepend="@"
                            valid={isValidEmail()}
                            okMessage="email correcto!"
                            errorMessage="escriba el correo con el que fue registrado."
                            value={email}
                            onChange={handleLoginRegisterInputChange}
                            disabled={loading}
                        />
                        <Input
                            name="password"
                            type="password"
                            label="Contraseña"
                            valid={!!password}
                            okMessage="password correcto!"
                            errorMessage="escriba su contraseña"
                            value={password}
                            onChange={handleLoginRegisterInputChange}
                            disabled={loading}
                        />
                        <Input
                            name="password2"
                            type="password"
                            label="Confirma contraseña"
                            valid={isValidPassword2()}
                            okMessage="las contraseñas coinciden."
                            errorMessage="escriba de nuevo su contraseña!"
                            value={password2}
                            onChange={handleLoginRegisterInputChange}
                            disabled={loading}
                        />
                        <Loading loading={loading} />
                        <ErrorAlert error={error} />
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading || !isValidLogin}>
                            Registrarse
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
