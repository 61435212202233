import { UserManajamentScreen } from '../components/admin/UserManajamentScreen';
import { NewUserScreen } from '../components/admin/NewUserScreen';
import { AltaProyectoScreen } from '../components/ui/AltaProyectoScreen';
import { AltaRelacionUeUser } from '../components/ui/AltaRelacionUeUser';
import { AltaDetalleProyecto } from '../components/ui/AltaDetalleProyecto';


export const componentPermissionsMap = [

    { permiso: 1, path: '/userManajament', component: UserManajamentScreen, menu: 'Manejo de usuarios' },
    { permiso: 1, path: '/capturaUsuario', component: NewUserScreen },
    { permiso: 1, path: '/altaRelacionUeUser', component: AltaRelacionUeUser },    
    { permiso: 2, path: '/altaProyectoScreen', component: AltaProyectoScreen },
    { permiso: 2, path: '/altaArticuloScreen', component: AltaDetalleProyecto },
    

];