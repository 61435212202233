import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { permisosData } from '../../permissions/permisosData';
import {  processError } from '../../helpers/utils';
import { Loading } from '../uiComponents/Loading';
import { selectUserData, clearSelectUserData } from '../../actions/um';
import { ErrorAlert } from '../uiComponents/ErrorAlert';
import { useLocalTaskStatus } from '../../hooks/useLocalTaskStatus';
import { Table } from '../uiComponents/Table';
import { InfoText } from '../uiComponents/InfoText';
import { fetchUsuarios } from '../../helpers/restCalls';
import { clearError } from '../../actions/ui';


export const UserManajamentScreen = ({ history }) => {
    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);
    const { permiso, uid } = auth;
    const [usuariosData, setUsuariosData] = useState();
    const [error, loading, setError, setLoading] = useLocalTaskStatus();

    useEffect(() => {
        try {
            setLoading(true);
            fetchUsuarios(
                auth, dispatch,
                ({ results }) => {
                    setUsuariosData(results)
                    setLoading(false);
                },
                setError);
        } catch (error) {
            console.error(error);
            setError(processError(error));
        }
    }, [auth, dispatch, permiso, uid, setError, setLoading]);

    const handleSelectUser = usuarioData => {
        dispatch(selectUserData(usuarioData));
        dispatch(clearError());
        history.push('./capturaUsuario');
    };

    const handleAddUser = () => {
        dispatch(clearSelectUserData());
        dispatch(clearError());
        history.push('./capturaUsuario');
    }

    const handleEditaRelUeUsr = usuarioData => {
        dispatch(selectUserData(usuarioData));
        history.push('./altaRelacionUeUser');
    };

    return (
        <div>
            <h3>Manejo usuarios <button className="btn btn-sm btn-outline-dark " onClick={handleAddUser} > + </button></h3>

            <Loading loading={loading} />
            <ErrorAlert error={error} />

            <Table
                titles={['Nombre', 'Correo', 'Permiso', 'UE\'s']}
                rowsData={usuariosData}
                rowsFn={
                    usuarioData => (
                        <tr
                            key={`ns${usuarioData.id}`}
                        >
                            <th onClick={() => handleSelectUser(usuarioData)} style={{ cursor: 'pointer' }}>
                                {usuarioData.nombre} {usuarioData.paterno} {usuarioData.materno}
                            </th>
                            <td>
                                <a className="btn btn-block btn-outline-success" href={`mailto:${usuarioData.correo}`}> {usuarioData.correo}</a>
                            </td>
                            <td onClick={() => handleSelectUser(usuarioData)} style={{ cursor: 'pointer' }}>
                                {permisosData.find(({ permiso }) => permiso === usuarioData.permiso)?.label}
                            </td>
                            <td>
                                {
                                    usuarioData.uesUsrData && (
                                        <div>
                                            {
                                                usuarioData.uesUsrData.map(({ ue }) =>
                                                (
                                                    <Fragment key={`um-${usuarioData.ue}${ue}`}>{ue}, </Fragment>
                                                )
                                                )
                                            }
                                        </div>
                                    )
                                }
                                <button
                                    className="btn btn-outline-secondary btn-sm"
                                    onClick={() => handleEditaRelUeUsr(usuarioData)}
                                >
                                    &#9999;
                                </button>
                            </td>
                        </tr>
                    )
                }
                shortRowsFn={
                    usuarioData => (
                        <tr
                            key={`short${usuarioData.id}`}
                        >
                            <td>
                                <div className="row">
                                    <div
                                        className="col"
                                        onClick={() => handleSelectUser(usuarioData)} style={{ cursor: 'pointer' }}
                                    >
                                        <InfoText
                                            text={`${permisosData.find(({ permiso }) => permiso === usuarioData.permiso)?.label}`}
                                            label={`${usuarioData.nombre} ${usuarioData.paterno} ${usuarioData.materno}`}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <InfoText
                                        label="Correo"
                                        text={(<a className="btn btn-block btn-outline-success btn-sm" href={`mailto:${usuarioData.correo}`}> {usuarioData.correo}</a>)}
                                        className="col"
                                    />
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <b>UE</b>&nbsp;
                                        <button
                                            className="btn btn-outline-secondary btn-sm"
                                            onClick={() => handleEditaRelUeUsr(usuarioData)}
                                        >
                                            &#9999;
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                }
            />
        </div>
    )
}
