import React from 'react'
import { useSelector } from 'react-redux';
import { addDetalleProyecto } from '../../actions/app';
import { articuloDataListManager } from '../../descriptors/articuloDescriptor';
import { detalleProyectoDescriptor } from '../../descriptors/detalleProyectoDescriptor';
import { getDefaultStateData } from '../../helpers/utils';
import { UpdateScreen } from '../uiComponents/UpdateScreen';
import { setValueForm } from '../../hooks/useForm';

export const AltaDetalleProyecto = ({ history }) => {

    const { ui, app } = useSelector(state => state);
    const { loading, error } = ui;
    const { selectedDetalleProyectoData = getDefaultStateData(detalleProyectoDescriptor) } = app;

    const handleOnChange = (name, value, values, setValues) => {
        if (name === 'articulo' && value) {
            const dl = articuloDataListManager.dataList.find(dl => dl.value === value.toUpperCase());
            if (dl && dl.item.partida) {
                setValueForm('partida', `${dl.item.partida}`, setValues);
            }
        }
    }

    return (
        <div>
            <UpdateScreen
                fieldsDescriptor={detalleProyectoDescriptor}
                selectedData={selectedDetalleProyectoData}
                loading={loading}
                error={error}
                action={addDetalleProyecto}
                labelsCancel={{ onIdExists: 'Volver', onIdNotExists: 'Cancelar' }}
                onChange={handleOnChange}
                calcelPath="altaProyectoScreen"
                afterUpdate={() => history.push('altaProyectoScreen')}
            />
        </div>
    )
}
