import { processError } from './utils';
import { startLoading, setErrorMsg, finishUpdate } from '../actions/ui';


export const addItemData = (
    insertFunction,
    updateFunction,
    itemData,
    insertAction,
    updateAction,
    validate = async (id, db, path, itemData) => { },
    beforeUpdate = itemData => itemData,
    beforeSelect = itemData => itemData,
    afterUpdate
) => {

    return async (dispatch, getState) => {
        const { id } = itemData;
        delete itemData.id;
        dispatch(startLoading());
        const fn = id ? updateAction : insertAction;
        try {
            await validate(id, itemData);
            const itemSnap = id
                ? await updateFunction(getState(), dispatch, id, beforeUpdate(itemData))
                : await insertFunction(getState(), dispatch, beforeUpdate(itemData))
            itemData.id = itemSnap ? itemSnap.id : id;
            if (afterUpdate) {
                await afterUpdate(itemData);
            }
            dispatch(fn(beforeSelect(itemData)));
            dispatch(finishUpdate());
        } catch (error) {
            console.error(error);
            dispatch(setErrorMsg(processError(error)));
            dispatch(fn(itemData));
        }
    }
}


export const addSubItemData = (parent, subItemData, subItemName, afterUpdate) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        try {
            delete subItemData.id;
            // const { id } = getState().app.selectedPacienteData;
            //await db.doc(`${parent}/${id}/addonData/${subItemName}`).set(subItemData);
            if (afterUpdate) {
                await afterUpdate(subItemData);
            }
            dispatch(finishUpdate());
        } catch (error) {
            console.error(error);
            dispatch(setErrorMsg(processError(error)));
        }
    }
}