import React, { Fragment, useEffect, useState } from 'react'
import { formatCurrency, getCantidad, validateInterger } from '../../helpers/utils';
import { setValueForm } from '../../hooks/useForm';

const meses = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
]

const getInitialState = (initialValue) => {
    const valoresMes = initialValue === '' ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : initialValue;
    const state = {
        total: getCantidad(valoresMes)
    };
    meses.forEach((mes, idx) => {
        state[mes] = valoresMes[idx];
    });
    return state;

}

const periodo = [4, 8, 12];
const limitaPeriodo = (limit, idx) => idx >= (limit - 12 / periodo.length) && idx < limit;

export const ProrrateoInput = (props) => {
    const {
        name,
        value,
        setFormValues,
        // ...rest
    } = props;

    const [values, setValues] = useState(getInitialState(value));

    useEffect(() => {
        setValueForm(name, meses.map(mes => values[mes]), setFormValues);
    }, [name, values, setFormValues])


    const handleInputChange = ({ target }) => {
        setValues(values => {
            const lName = target.name;
            const lValue = target.value;
            const lValues = { ...values };
            if (lName === 'total') {
                lValues.total = Number(lValue);
                const vmes = parseInt(lValue / 12);
                meses.forEach(mes => {
                    lValues[mes] = vmes;
                });
                lValues[meses[11]] += lValue - vmes * 12;

            } else {
                lValues.total = Number(lValues.total) + Number(lValue) - lValues[lName];
                lValues[lName] = Number(lValue);
            }
            return lValues;
        });
    }

    return (
        <div className="container">
            {/* <div className="input-group mb-3">
                <input
                    name={`total`}
                    type="number"
                    className="form-control"
                    value={values['total']}
                    onChange={handleInputChange}
                    {...rest}
                />
                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                >
                    Meses
                </button>
            </div> */}
            <div className="collapse show" id="collapseExample">
                <div className="card card-body show">
                    {
                        periodo.map(limit => (
                            <Fragment key={`${name}-table-${limit}`} >
                                <div className="d-none d-md-block">
                                    <table >
                                        <thead>
                                            <tr>
                                                {
                                                    meses.filter((mes, idx) => limitaPeriodo(limit, idx)).map(mes => (
                                                        <th key={`${name}-th-${mes}`} className="text-capitalize">{mes}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {
                                                    meses.filter((mes, idx) => limitaPeriodo(limit, idx)).map(mes => (
                                                        <td key={`${name}-tb-${mes}`}>
                                                            <input
                                                                type="number"
                                                                name={mes}
                                                                className={`form-control ${validateInterger(values[mes]) ? 'is-valid' : 'is-invalid'}`}
                                                                aria-label={mes}
                                                                aria-describedby="basic-addon1"
                                                                value={values[mes]}
                                                                onChange={handleInputChange}
                                                            />
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-md-none">
                                    {
                                        meses.filter((mes, idx) => limitaPeriodo(limit, idx)).map(mes => (
                                            <div key={`${name}-tb-${mes}`} className="mb-3">
                                                <label htmlFor={`${name}-${mes}`} className="text-capitalize form-label ">
                                                    {mes}
                                                </label>
                                                <input
                                                    type="number"
                                                    id={`${name}-${mes}`}
                                                    name={mes}
                                                    className="form-control"
                                                    aria-label={mes}
                                                    aria-describedby="basic-addon1"
                                                    value={values[mes]}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </Fragment>
                        ))
                    }

                </div>
            </div>
            <h3 className="text-end border mb-3">
                <span className="text-info">Total:</span> {formatCurrency(getCantidad(value))}
            </h3>
        </div>
    )
}
