
export const isMobileOrTablet = () => {
    const ck = a => /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4));
    return ck(navigator.userAgent || navigator.vendor || window.opera);
};

export const sendWhatsapp = (phone, text) => {
    const fPhone = encodeURI(`+52${phone}`);
    const fText = encodeURI(text);
    const url = isMobileOrTablet()
        ? `whatsapp://send?text=${fText}&phone=${fPhone}&abid=${fPhone}`
        : `https://web.whatsapp.com/send?phone=${fPhone}&text=${fText}`;
    window.open(url, '_blank');
}

export const processError = error => {
    if (error.code && error.message) {
        switch (error.code) {
            case 'failed-precondition':
            case 'invalid-argument':
                return 'Índice faltante comuníquese con el administrador del sistema';
            default:
        }
        return `${error.code}- ${error.message}`
    } else if (error.message) {
        return error.message;
    } else if (error.msg) {
        return error.msg;
    } else if (Array.isArray(error.errors)){
        return error.errors.map(e => processError(e)).join('\n');
    }
    return error;
}
/** Formateadores */

export const formatEmail = email => email.replaceAll(/\s/g, '').toLowerCase();

export const formatCelular = celular => String(celular).replaceAll(/(\s|\D)/g, '').toLowerCase();


const numberFormat2 = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });

export const formatCurrency = numberFormat2.format;

/** Validadores */

export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validateCelular = celular => formatCelular(celular).length === 10;

export const validateClaveElector = claveElector => !claveElector || claveElector === '' || isNaN(claveElector) || formatCelular(claveElector).length === 18;

export const validateNotEmpty = value => !!value;

export const validateSelectNotEmpty = value => value !== '';

export const validateInterger = value => /^\d+$/g.test(value);

export const validateCurrency = value => /^[0-9]+(\.[0-9]{1,2}){0,1}$/.test(value);

/** Utilerias Firebase */
export const snapToData = (itemSnap) => ({
    id: itemSnap.id,
    ...itemSnap.data()
});

export const fetchDocData = async (db, documentPath, setter) => {
    if (db && documentPath) {
        const itemSnap = await db.doc(documentPath).get();
        const itemData = itemSnap.exists ? snapToData(itemSnap) : null;
        if (setter) {
            setter(itemData);
        } else {
            return itemData;
        }
    }
}

export const fetchData = async (query, setter, setLoading, setError) => {
    try {
        if (query) {
            if (setLoading) {
                setLoading(true);
            }
            const items = [];
            const itemsSnap = await query.get();
            itemsSnap.forEach(itemSnap => {
                items.push(snapToData(itemSnap));
            });
            if (setLoading) {
                setLoading(false);
            }
            if (setter) {
                setter(items);
            } else {
                return items;
            }
        }
    } catch (error) {
        console.error(error);
        if (setLoading) {
            setLoading(false);
        }
        if (setError) {
            setError(processError(error));
        } else {
            throw error;
        }
    }
}

export const onData = (query, setter, setLoading, setError) => {
    setLoading(true);
    const realease = query.onSnapshot(itemsSnap => {
        setLoading(false);
        const items = [];
        itemsSnap.docs.forEach(itemSnap => {
            items.push(snapToData(itemSnap));
        });
        setter(items);
    }, error => {
        console.error(error);
        setError(processError(error));
    });
    return realease;
}

export const fetchDataAndGetFirst = async (query, onData) => {
    if (query) {
        const itemsSnap = await query.get();
        if (itemsSnap.size > 0) {
            const itemData = snapToData(itemsSnap.docs[0]);
            if (onData) {
                onData(itemData);
            }
            return itemData;
        }
    }
}

export const asyncFetchData = async (query, setter, onFetch, setLoading, setError) => {
    try {
        setError();
        if (query) {
            setLoading(true);
            const items = [];
            const fetchs = [];
            const itemsSnap = await query.get();

            for (let index = 0; index < itemsSnap.docs.length; index++) {
                const item = snapToData(itemsSnap.docs[index]);
                if (onFetch) {
                    fetchs.push(onFetch(item, itemsSnap.size, items));
                }
                items.push(item);
            }
            setter(items);
            await Promise.all(fetchs);

            setter(items);
            setLoading(false);
        }
    } catch (error) {
        console.error(error);
        setError(processError(error));
        setLoading(false);
    }
}

export const setDataPropietario = (propietario, cadenaData) => {
    cadenaData.propietario = {
        id: propietario.id,
        value: propietario.email,
        item: propietario
    }
}

/**Herramientas */
export const getHost = () => `${window.location.protocol}//${window.location.hostname}${(window.location.port === '' || window.location.port === 80) ? '' : (':' + window.location.port)}`;

export const orderCollectionBy = (itemsData, fields, setter) => setter(itemsData.sort((a, b) => {
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        if (a[field] > b[field]) {
            return 1;
        }
        if (a[field] < b[field]) {
            return -1;
        }
    }
    return 0;
}));

export const getDefaultStateData = fieldsDescriptor => {
    const defaultStateData = {};
    fieldsDescriptor.forEach(
        ({ name, type, selectItems, defaultValue }) =>
            defaultStateData[name] = (type === 'select' && selectItems && selectItems.length > 0) ? selectItems[0].value : defaultValue? defaultValue : ''
    );
    return defaultStateData;
}

export const edad = fechaNacimiento => {
    const now = new Date();
    return new Date(now.getTime() - new Date(fechaNacimiento).getTime() + now.getTimezoneOffset() * 60 * 1000).getFullYear() - 1970;
}

export const getCantidad = cantidades => cantidades
    ? (
        Array.isArray(cantidades)
            ? cantidades.reduce((ac, cantidad, idx) => ac + (idx < 12 ? cantidad : 0))
            : cantidades
    )
    : 0;


export const capitalize = str => str && str.length > 0 ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '';

export const nomalizeId = item => {
    if (item._id) {
        item.id = item._id;
        delete item._id;
    }
    return item;
}