import { articuloDescriptor } from "./articuloDescriptor";
import { actividadAccion, cantidad, costo, partida } from "./communsDescriptors";

export const detalleProyectoDescriptor = [
    articuloDescriptor,
    cantidad,
    actividadAccion,
    //partidaDescriptor,
    partida,
    costo,
];
