import { useEffect, useMemo, useState } from 'react';



const getValue = (target, values) => {
    if (target.type === 'checkbox') {
        const ckValues = values[target.name] ? values[target.name].split(',') : [];
        return target.checked
            ? [...ckValues, target.value].join()
            : ckValues.filter(ckValue => ckValue !== target.value).join();
        ;
    }
    return target.value;
}

export const setValueForm = (fieldName, value, setValues) => {
    setValues(values => {
        return {
            ...values,
            [fieldName]: typeof value === "function" ? value() : value,
            fieldName
        }
    });
}

export const useForm = (initialState = {}, onChange) => {

    const [values, setValues] = useState(initialState);

    // const reset = () => {
    //     setValues(initialState);
    // }

    useEffect(() => {
        if (values.fieldName && onChange) {
            onChange(
                values.fieldName,
                values[values.fieldName],
                values,
                setValues
            );
        }
    }, [values, onChange])


    const handleInputChange =
        useMemo(() =>
            ({ target }) => {
                setValueForm(target.name, values => getValue(target, values), setValues);
            }
            , []);


    return [values, handleInputChange, setValues];

}