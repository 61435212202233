import { types } from "../types/types";

const initialState = {
}

export const umReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case types.umSelectUserData:
            return {
                ...state,
                selectUser: action.payload
            }
        case types.umClearSelectUserData:
            newState = { ...state };
            delete newState.selectUser;
            return newState;
        case types.umSelectZoneData:
            return {
                ...state,
                selectZona: action.payload
            }
        case types.umClearSelectZoneData:
            newState = { ...state };
            delete newState.selectZona;
            return newState;
        default:
            return state;
    }
}