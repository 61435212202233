import { startLogout } from "../actions/auth";
import { processError } from "./utils";

const host = 'https://git.iea.edu.mx:8443/api';
//const host = 'http://test.iea.edu.mx:8443/api';

const customFetch = async (url, options, dispatch, onOk, onError) => {
    try {
        const response = await fetch(url, options);
        if (response.status === 401) {
            onError && onError('Session invalida');
            dispatch && dispatch(startLogout());
            return;
        }
        const data = await response.json();
        if (response.ok) {
            if (data.results || data.result) {
                data.results = (data.results ? data.results : data.result).map(({ uid, ...rest }) => uid ? { ...rest, id: uid } : rest);
                delete data.result;
            }
            onOk && onOk(data);
        } else {
            onError && data.msg && onError(data.msg);
            onError && onError(data);
        }
    } catch (error) {
        const { message } = error;
        console.error(message);
        onError && onError(message);
    }
}

export const fetchLogin = (correo, password, dispatch, onOk, onError) => customFetch(
    `${host}/auth/login`,
    {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ correo, password })
    }, dispatch, onOk, onError);


export const fetchProyectos = async (auth, dispatch, onOk, onError) => customFetch(
    `${host}/proyectos`,
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-token': auth.token
        }
    }, dispatch, onOk, onError);


export const fetchArticulos = async (termino, auth, dispatch, onOk, onError) => customFetch(
    `${host}/buscar/Articulo/${encodeURIComponent(termino)}`,
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-token': auth.token
        }
    }, dispatch, onOk, onError);


export const altaProyectos = async (auth, body, dispatch) => {
    let tData;
    let tError;
    await customFetch(
        `${host}/proyectos`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-token': auth.token
            },
            body: JSON.stringify(body)
        }, dispatch, data => tData = data, error => tError = error);
    if (tError) {
        const msg = processError(tError)
        console.log(msg);
        throw new Error(msg);
    }
    const { uid, ...rest } = tData;
    return { id: uid, ...rest };
}

export const updateProyectos = async (auth, id, body, dispatch) => {
    let tData;
    let tError;
    await customFetch(
        `${host}/proyectos/${id}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-token': auth.token
            },
            body: JSON.stringify(body)
        }, dispatch, data => tData = data, error => tError = error);
    if (tError) {
        throw new Error(tError);
    }
    const { uid, ...rest } = tData;
    return { id: uid, ...rest };
}



export const fetchUes = async (auth, dispatch, onOk) => customFetch(
    `${host}/ues`,
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-token': auth.token
        }
    }, dispatch, onOk);


export const altaArticulosProyectos = async (auth, id, body, dispatch) => {
    let tData;
    let tError;
    await customFetch(
        `${host}/articulosProyectos/${id}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-token': auth.token
            },
            body: JSON.stringify(body)
        }, dispatch, data => tData = data, error => tError = error);
    if (tError) {
        throw new Error(tError);
    }
    const { _id, ...rest } = tData.articulo;
    return { id: _id, ...rest };
}

export const deleteArticulosProyectos = async (auth, pid, id, dispatch, action) => {
    let tData;
    let tError;
    await customFetch(
        `${host}/articulosProyectos/${pid}/${id}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'x-token': auth.token
            }
        }, dispatch, data => tData = data, error => tError = error);
    if (tError) {
        throw new Error(tError);
    }
    dispatch(action(id));
    return tData;
}

export const updateArticulosProyectos = async (auth, pid, id, body, dispatch) => {
    let tData;
    let tError;
    await customFetch(
        `${host}/articulosProyectos/${pid}/${id}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-token': auth.token
            },
            body: JSON.stringify(body)
        }, dispatch, data => tData = data, error => tError = error);
    if (tError) {
        throw new Error(tError);
    }
    const { _id, ...articulo } = tData.articulo;
    return { ...articulo, id: _id };
}

export const fetchUsuarios = async (auth, dispatch, onOk, onError) => customFetch(
    `${host}/buscar/usuarios/rol/USUARIO_PRESUPUSTO_EGRESOS`,
    {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-token': auth.token
        }
    }, dispatch, onOk, onError);

export const altaUsuario = async (auth, body, dispatch) => {
    let tData;
    let tError;
    await customFetch(
        `${host}/usuarios`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-token': auth.token
            },
            body: JSON.stringify(body)
        }, dispatch, data => tData = data, error => tError = error);
    if (tError) {
        const msg = processError(tError)
        console.log(msg);
        throw new Error(msg);
    }
    const { uid, ...rest } = tData.usuario;
    return { id: uid, ...rest };
}


export const updateUsuario = async (id, auth, body, dispatch) => {
    let tData;
    let tError;
    await customFetch(
        `${host}/usuarios/${id}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-token': auth.token
            },
            body: JSON.stringify(body)
        }, dispatch, data => tData = data, error => tError = error);
    if (tError) {
        const msg = processError(tError)
        console.log(msg);
        throw new Error(msg);
    }
    const { uid, ...rest } = tData;
    return { id: uid, ...rest };
}

export const altaUeUsr = async (id, auth, body, dispatch) => {
    let tData;
    let tError;
    await customFetch(
        `${host}/uesUsr/${id}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-token': auth.token
            },
            body: JSON.stringify(body)
        }, dispatch, data => tData = data, error => tError = error);
    if (tError) {
        const msg = processError(tError)
        console.log(msg);
        throw new Error(msg);
    }
    return tData;
}

export const deleteUeUsr = async (id, ue, auth, dispatch) => {
    let tData;
    let tError;
    await customFetch(
        `${host}/uesUsr/${id}/${ue}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'x-token': auth.token
            }
        }, dispatch, data => tData = data, error => tError = error);
    if (tError) {
        throw new Error(tError);
    }
    return tData;
}