import React from 'react'

export const ErrorAlert = ({ error }) => {
    return (
        <>
            {
                error && (
                    <div className="alert alert-danger mt-2" role="alert">
                        {error}
                    </div>
                )
            }
        </>
    )
}
