import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { login, startLoginEmailPassword } from '../../actions/auth';

import { validateEmail } from '../../helpers/utils';
import { useForm } from '../../hooks/useForm';
import { ErrorAlert } from '../uiComponents/ErrorAlert';
import { Input } from '../uiComponents/Input'
import { Loading } from '../uiComponents/Loading';

const clientId = "183624400785-4u2qcn6p227g8rf3bev1eo26kvf3pkep.apps.googleusercontent.com";

const renderGoogleButton = () =>
    window.isInitGoogle && window.google?.accounts?.id?.renderButton(
        document.getElementById("googleonSignIn"),
        { theme: "outline", size: "large" }  // customization attributes
    );

const initGoogleButton = async handleCredentialResponse => {
    if (window.isInitGoogle === undefined) {
        if (!window.google?.accounts) {
            const reintenta = () => {
                window.initGoogleWaiting = undefined;
                initGoogleButton(handleCredentialResponse);
            }
            !window.initGoogleWaiting && (window.initGoogleWaiting = setTimeout(reintenta, 100));
            return false;
        }

        console.log("Initialize google accounts api");
        await window.google.accounts.id.initialize({
            client_id: clientId,
            callback: handleCredentialResponse
        });
        window.isInitGoogle = true;
        await renderGoogleButton();
        return false;
    }
    return true;
}

const setOnSignIn = async (dispatch, login) => {
    const handleCredentialResponse = (response) => {
        fetch(`https://git.iea.edu.mx:8443/api/auth/google`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id_token: response.credential })
        })
            .then(resp => resp.json())
            .then(({ usuario, token }) => {
                dispatch(login({ ...usuario, token }));
            })
            .catch(console.log);
    }
    await initGoogleButton(handleCredentialResponse) && await renderGoogleButton();

}

export const LoginScreen = () => {

    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.ui);

    useEffect(() => {
        setOnSignIn(dispatch, login);
        return () => {
        }
    }, [dispatch]);

    const [formLoginValues, handleLoginInputChange] = useForm({
        email: '',
        password: ''
    });

    const { email, password } = formLoginValues;

    const isValidLogin = !validateEmail(email) || !password;

    const handleSubmitLogin = e => {
        e.preventDefault();
        dispatch(startLoginEmailPassword(email, password));
    }

    return (
        <div className="container m-0 m-sm-3">
            <h1>Anteproyecto Presupuesto Egresos 2024</h1>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmitLogin}>
                        <Input
                            name="email"
                            type="email"
                            label="email"
                            prepend="@"
                            valid={validateEmail(email)}
                            okMessage="email correcto!"
                            errorMessage="escriba un correo electrónico válido."
                            value={email}
                            onChange={handleLoginInputChange}
                            disabled={loading}
                        />
                        <Input
                            name="password"
                            type="password"
                            label="Password"
                            valid={!!password}
                            okMessage="password correcto!"
                            errorMessage="escriba su contraseña"
                            value={password}
                            onChange={handleLoginInputChange}
                            disabled={loading}
                        />
                        <Loading loading={loading} />
                        <ErrorAlert error={error} />
                        <div className="row">
                            <button
                                type="submit"
                                className="btn btn-primary col mx-2"
                                disabled={loading || isValidLogin}>
                                Ingresar
                            </button>
                            <div className="col">
                                <div id="googleonSignIn" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
